<template>
  <div id='contact'>
    <PageTitle text='お問い合わせ' />
    <p>
      当協会へのお問い合わせは
      <span class='font-weight-black'>jpff.webpage@gmail.com</span>
      へとご連絡ください。
    </p>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'

export default {
  components: { PageTitle },
}
</script>
